export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/portal/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/portal/dashboard',
    name: 'portal-dashboard',
    component: () => import('@/views/portal/Dashboard.vue'),
    meta: {
      action: 'read',
      resource: 'dashboard',
    },
  },

  {
    path: '/portal/centers-types',
    name: 'center-types',
    component: () => import('@/views/portal/CentersTypes.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipos de Centros de Costos',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
      pageTitle: 'Tipos de Centros de Costos',
      resource: 'cost-center-type',
    },
  },
  {
    path: '/portal/centers-types-add',
    name: 'centers-types-add',
    component: () => import('@/views/portal/CentersTypesAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Tipos de Centro',
          to: 'centers-types',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
      pageTitle: 'Agregar Tipos de Centro',
      resource: 'cost-center-type',
    },
  },
  {
    path: '/portal/centers-types-edit/:id',
    name: 'center-types-edit',
    component: () => import('@/views/portal/CentersTypesEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Tipos de Centro',
          to: '../centers-types',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      pageTitle: 'Editar Tipo de Centro',
      resource: 'cost-center-type',
    },
  },

  {
    path: '/portal/administrative-management',
    name: 'administrative-management',
    component: () => import('@/views/portal/AdministrativeManagment.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Gerencias Directivas',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
      pageTitle: 'Gerencias Directivas',
      resource: 'administrative-management',
    },
  },
  {
    path: '/portal/administrative-management-add',
    name: 'administrative-management-add',
    component: () => import('@/views/portal/AdministrativeManagementAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Gerencias Directivas',
          to: 'administrative-management',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
      pageTitle: 'Gerencias Directivas - Agregar',
      resource: 'administrative-management',
    },
  },
  {
    path: '/portal/administrative-management-edit/:id',
    name: 'administrative-management-edit',
    component: () => import('@/views/portal/AdministrativeManagementEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Gerencia Directivas',
          to: '../administrative-management',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      pageTitle: 'Editar Gerencia Directivas',
      resource: 'administrative-management',
    },
  },

  {
    path: '/portal/cost-centers',
    name: 'cost-centers',
    component: () => import('@/views/portal/CostCenters.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Centros de Costos',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
      pageTitle: 'Centros de Costos',
      resource: 'cost-center',
    },
  },
  {
    path: '/portal/cost-centers-edit/:id',
    name: 'cost-centers-edit',
    component: () => import('@/views/portal/CostCentersEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Centros de Costos',
          to: '../cost-centers',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      pageTitle: 'Editar Centros de Costos',
      resource: 'cost-center',
    },
  },

  {
    path: '/portal/accounts-accounting',
    name: 'accounts-accounting',
    component: () => import('@/views/portal/AccountsAcounting.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Cuentas Contables',
        },
        {
          text: 'Listado / Consulta',
          active: true,
        },
      ],
      pageTitle: 'Cuentas Contables',
      resource: 'accounting-account',
    },
  },
  {
    path: '/portal/accounts-accounting-edit/:id/:center',
    name: 'accounts-accounting-edit',
    component: () => import('@/views/portal/AccountsAcountingEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Cuentas Contables',
        },
        {
          text: 'Listado / Edición',
          active: true,
        },
      ],
      pageTitle: 'Edición Cuentas Contables',
      resource: 'accounting-account',
    },
  },
  {
    path: '/portal/accounts-accounting-aux',
    name: 'accounts-accounting-aux',
    component: () => import('@/views/portal/AccountsAcountingAux.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Cuentas Contables',
        },
        {
          text: 'Listado / Auxiliar',
          active: true,
        },
      ],
      pageTitle: 'Auxilar Cuentas Contables',
      resource: 'accounting-account-assistant',
    },
  },

  {
    path: '/portal/accounts-types',
    name: 'accounts-types',
    component: () => import('@/views/portal/AccountsTypes.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipos de Cuentas',
        },
        {
          text: 'Listado / Consulta',
          active: true,
        },
      ],
      pageTitle: 'Tipos de Cuentas',
      resource: 'accounting-account-type',
    },
  },
  {
    path: '/portal/accounts-types-add',
    name: 'accounts-types-add',
    component: () => import('@/views/portal/AccountsTypesAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Tipos de Cuentas',
          to: 'accounts-types',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
      pageTitle: 'Agregar Tipo de Cuenta',
      resource: 'accounting-account-type',
    },
  },
  {
    path: '/portal/accounts-types-edit/:id',
    name: 'accounts-types-edit',
    component: () => import('@/views/portal/AccountsTypesEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Tipos de Cuentas',
          to: '../accounts-types',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      pageTitle: 'Editar Tipos de Cuentas',
      resource: 'accounting-account-type',
    },
  },

  {
    path: '/portal/accounts-classification',
    name: 'accounts-classification',
    component: () => import('@/views/portal/AccountsClassification.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Categorías de Cuentas Contables',
        },
        {
          text: 'Listado / Consulta',
          active: true,
        },
      ],
      pageTitle: 'Categorías de Cuentas Contables',
      resource: 'accounting-account-category',
    },
  },
  {
    path: '/portal/accounts-classification-add',
    name: 'accounts-classification-add',
    component: () => import('@/views/portal/AccountsClassificationAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Categorías de Cuentas Contables',
          to: 'accounts-classification',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
      pageTitle: 'Agregar Categoría de Cuenta',
      resource: 'accounting-account-category',
    },
  },
  {
    path: '/portal/accounts-classification-edit/:id',
    name: 'accounts-classification-edit',
    component: () => import('@/views/portal/AccountsClassificationEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Categoría de Cuenta Contable',
          to: '../accounts-classification',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
      pageTitle: 'Editar Categoría de Cuenta Contable',
      resource: 'accounting-account-category',
    },
  },

  {
    path: '/portal/reports-movements',
    name: 'reports-movements',
    component: () => import('@/views/portal/ReportsMovements.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Reportes',
        },
        {
          text: 'Movimiento',
          active: true,
        },
      ],
      pageTitle: 'Reportes de Movimiento',
      resource: 'branch-profitability-movements-report',
    },
  },
  {
    path: '/portal/reports-financial-rentability',
    name: 'reports-financial-rentability',
    component: () => import('@/views/portal/ReportsFinancialRentability.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Reporte Rentabilidad Financiera',
        },
      ],
      pageTitle: 'Reporte Rentabilidad Financiera',
      text: 'Reporte Rentabilidad Financiera',
      resource: 'branch-financial-rentability-report',
    },
  },
  {
    path: '/portal/reports-redistribution',
    name: 'reports-redistribution',
    component: () => import('@/views/portal/ReportsRedistribution.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Reporte Reporte Redistribución',
        },
      ],
      pageTitle: 'Reporte Rentabilidad Distribuida',
      text: 'Reporte Rentabilidad Distribuida',
      action: 'read',
      resource: 'branch-redistribution-report',
    },
  },
  {
    path: '/portal/reports-financial-rentability-dynamic',
    name: 'reports-financial-rentability-dynamic',
    component: () => import('@/views/portal/ReportsFinancialRentabilityDynamic.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Reporte Rentabilidad Financiera Dínamica',
        },
      ],
      pageTitle: 'Reporte Rentabilidad Financiera Dínamica',
      text: 'Reporte Rentabilidad Financiera Dínamica',
      resource: 'branch-financial-rentability-report-dynamic',
    },
  },
  {
    path: '/portal/reports-redistribution-dynamic',
    name: 'reports-redistribution-dynamic',
    component: () => import('@/views/portal/ReportsRedistributionDynamic.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Reporte Reporte Redistribución Dínamica',
        },
      ],
      pageTitle: 'Reporte Rentabilidad Distribuida Dínamica',
      text: 'Reporte Rentabilidad Distribuida Dínamica',
      action: 'read',
      resource: 'branch-redistribution-report-dynamic',
    },
  },

  {
    path: '/portal/reports-rentability',
    name: 'reports-rentability',
    component: () => import('@/views/portal/ReportsRentability.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Reglas y Trámites',
        },
      ],
      pageTitle: 'Reglas y Trámites',
      text: 'Reglas y Trámites',
      action: 'read',
      resource: 'reports-rentability',
    },
  },
  {
    path: '/portal/reports-rentability-edit/:id',
    name: 'reports-rentability-edit',
    component: () => import('@/views/portal/ReportsRentabilityEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Reglas y Trámites Edición',
        },
      ],
      pageTitle: 'Reglas y Trámites Edición',
      text: 'Reglas y Trámites Edición',
      action: 'read',
      resource: 'reports-rentability',
    },
  },

  {
    path: '/portal/reports-aux',
    name: 'reports-aux',
    component: () => import('@/views/portal/ReportsAux.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Reportes',
        },
        {
          text: 'Auxiliares',
          active: true,
        },
      ],
      pageTitle: 'Catálogo de Auxiliares',
      resource: 'branch-aux-report',
    },
  },

  {
    path: '/portal/mortgage-range',
    name: 'mortgage-range',
    component: () => import('@/views/portal/MortgageRange.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Rangos de Hipotecas',
          active: true,
        },
      ],
      pageTitle: 'Rangos de Hipotecas',
      resource: 'mortgage-loan-range',

    },
  },
  {
    path: '/portal/mortgage-range-add',
    name: 'mortgage-range-add',
    component: () => import('@/views/portal/MortgageRangeAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Hipotecas',
          to: 'mortgage-range',
        },
        {
          text: 'Agregar',
          active: true,
        },
      ],
      pageTitle: 'Agregar Rangos de Hipotecas',
      resource: 'mortgage-loan-range',
    },
  },
  {
    path: '/portal/admin/period-management',
    name: 'period-management',
    component: () => import('@/views/portal/PeriodManagement.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Gestión de Periodos',
        },
      ],
      pageTitle: 'Gestión de Periodos',
      text: 'Gestión de Periodos',
      resource: 'period-management',
    },
  },

  {
    path: '/portal/admin/auxiliary-approval',
    name: 'auxiliary-approval',
    component: () => import('@/views/portal/AuxiliaryApproval.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Aprobación de Auxiliares',
        },
      ],
      pageTitle: 'Aprobación de Auxiliares',
      text: 'Aprobación de Auxiliares',
      resource: 'auxiliary-approval',
    },
  },

  {
    path: '/portal/admin/auxiliary-list',
    name: 'auxiliary-list',
    component: () => import('@/views/portal/AuxiliaryList.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Gestión de Auxiliares',
        },
      ],
      pageTitle: 'Gestión de Auxiliares',
      text: 'Gestión de Auxiliares',
      resource: 'auxiliary-management',
    },
  },
  {
    path: '/portal/admin/auxiliary-add',
    name: 'auxiliary-add',
    component: () => import('@/views/portal/AuxiliaryAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Agregar Auxiliar',
        },
      ],
      pageTitle: 'Agregar Auxiliar',
      text: 'Agregar Auxiliar',
      resource: 'auxiliary-management',
    },
  },
  {
    path: '/portal/admin/auxiliary-edit/:id',
    name: 'auxiliary-edit',
    component: () => import('@/views/portal/AuxiliaryEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Editar Auxiliar',
        },
      ],
      pageTitle: 'Editar Auxiliar',
      text: 'Editar Auxiliar',
      resource: 'auxiliary-management',
    },
  },

  {
    path: '/portal/admin/auxiliary-approvers/:id',
    name: 'auxiliary-approvers',
    component: () => import('@/views/portal/AuxiliaryApprovers.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Listado de Aprobadores',
        },
      ],
      pageTitle: 'Listado de Aprobadores',
      text: 'Listado de Aprobadores',
      resource: 'auxiliary-management-approves',
    },
  },
  {
    path: '/portal/admin/auxiliary-approvers-edit/:id',
    name: 'auxiliary-approvers-edit',
    component: () => import('@/views/portal/AuxiliaryApproversEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Editar Aprobador',
        },
      ],
      pageTitle: 'Editar Aprobador',
      text: 'Editar Aprobador',
      resource: 'auxiliary-management-approves',
    },
  },
  {
    path: '/portal/admin/auxiliary-approvers-add/:origin',
    name: 'auxiliary-approvers-add',
    component: () => import('@/views/portal/AuxiliaryApproversAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Agregar Aprobador',
        },
      ],
      pageTitle: 'Agregar Aprobador',
      text: 'Agregar Aprobador',
      resource: 'auxiliary-management-approves',
    },
  },
  {
    path: '/portal/admin/auxiliary-approvers-roles',
    name: 'auxiliary-approvers-roles',
    component: () => import('@/views/portal/AuxiliaryApproversRoles.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Editar Role Aprobadores',
        },
      ],
      pageTitle: 'Permisos Rol',
      text: 'Editar Roles Aprobadores',
      resource: 'auxiliary-management-approves',
    },
  },

  {
    path: '/portal/admin/roles-list',
    name: 'roles-list',
    component: () => import('@/views/portal/RolesList.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Listado de Roles',
        },
      ],
      pageTitle: 'Listado de  Roles',
      text: 'Listado de Roles',
      resource: 'user-roles-list',
    },
  },
  {
    path: '/portal/admin/roles-add',
    name: 'roles-add',
    component: () => import('@/views/portal/RolesAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Agregar Rol',
        },
      ],
      pageTitle: 'Agregar Rol',
      text: 'Agregar Rol',
      resource: 'user-roles-list',
    },
  },

  {
    path: '/portal/admin/roles-edit',
    name: 'roles-edit',
    component: () => import('@/views/portal/RolesEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Editar Rol',
        },
      ],
      pageTitle: 'Editar Rol',
      text: 'Editar Rol',
      resource: 'user-roles-list',
    },
  },
  {
    path: '/portal/admin/roles-permissions',
    name: 'roles-permissions',
    component: () => import('@/views/portal/RolesPermissions.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Editar Permisos de Roles',
        },
      ],
      pageTitle: 'Editar Permisos de Roles',
      text: 'Editar Permisos de Roles',
      resource: 'user-roles-list',
    },
  },

  {
    path: '/portal/admin/user-list',
    name: 'user-list',
    component: () => import('@/views/portal/UsersList.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Listado de Usuarios',
        },
      ],
      pageTitle: 'Listado de  Usuarios',
      text: 'Listado de Usuarios',
      resource: 'users-list',
    },
  },

  {
    path: '/portal/admin/inter-bank-homologation',
    name: 'inter-bank-homologation',
    component: () => import('@/views/portal/homolgationInterBank.vue'),
    meta: {
      action: 'read',
      breadcrumb: [
        {
          text: 'Homologación Interbancos',
        },
      ],
      pageTitle: 'Homologación Interbancos',
      text: 'Homologación Interbancos',
      resource: 'inter-bank-homologation',
    },
  },
  {
    path: '/portal/admin/inter-bank-homologation-edit/:id',
    name: 'inter-bank-homologation-edit',
    component: () => import('@/views/portal/homologationInterBankEdit.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Homologación Interbancos - Editar',
        },
      ],
      pageTitle: 'Homologación Interbancos - Editar',
      text: 'Homologación Interbancos - Editar',
      resource: 'inter-bank-homologation',
    },
  },
  {
    path: '/portal/admin/inter-bank-homologation-add',
    name: 'inter-bank-homologation-add',
    component: () => import('@/views/portal/homologationInterBankAdd.vue'),
    meta: {
      action: 'write',
      breadcrumb: [
        {
          text: 'Homologación Interbancos - Agregar',
        },
      ],
      pageTitle: 'Homologación Interbancos - Agregar',
      text: 'Homologación Interbancos - Agregar',
      resource: 'inter-bank-homologation',
    },
  },
]
